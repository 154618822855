@import url("https://fonts.googleapis.com/css?family=Roboto+Mono");

* {
  box-sizing: border-box;
  user-select: none;
}

html {
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Roboto Mono";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.top {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAGklEQVQYV2NkYGAwZmBgOMuABBiROTA2hYIAY3ABBlRnmx0AAAAASUVORK5CYII=");
  z-index: 999;
}

.color {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #212325;
}

.color .txt {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  font-size: 4rem;
  font-weight: 600;
  transition: 600ms;
  transition-property: color;
  text-transform: uppercase;
}

.colors {
  position: fixed;
  bottom: 0;
  height: 2vh;
  width: 100vw;
  z-index: 2;
  transition: 600ms;
  transition-property: background-color;
}

.info {
  text-decoration: none;
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.2rem;
  background-color: rgba(255, 255, 255, 0);
  color: rgba(255, 255, 255, 0.6);
  z-index: 3;
  transition: 300ms;
  transition-property: background-color;
}

.info:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

@media only screen and (min-width: 48rem) {
  .color .txt {
    font-size: 8rem;
    font-weight: 600;
  }
}
